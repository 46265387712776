import React, { useState, useEffect } from 'react';
import ContactUsForm from './contact-us-form';
import ThankyouModal from './thankyou-modal';

const Navbar: React.FC = () => {
  const [showNav, setShowNav] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
    setLastScrollY(window.scrollY);
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const closeThankyouModal = () => {
    setIsResponseModalOpen(false);
  };

  const handleError = (isError:boolean) =>{
    setIsError(isError)
    setIsResponseModalOpen(true);
  }

  return (
    <nav className={`fixed w-full bg-white z-999 text-black shadow-lg transition-transform transform
    ${showNav ? 'translate-y-0' : '-translate-y-full'} h-[80px] md:h-[100px] grid content-center`}>
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="w-1/2 md:w-1/3">
          <img src="./Ovik-Finance-Logo.png" alt="" className='max-h-12' />
        </div>

        <span className='flex justify-between items-center gap-15 font-semibold'>
          <span className='hidden md:flex justify-between items-center gap-15'>
            <p className='text-lg cursor-pointer hover:text-primary transform transition-transform duration-300 hover:scale-110'
               onClick={() => scrollToSection('our-services')}>
             Services
            </p>
            <p className='text-lg cursor-pointer hover:text-primary transform transition-transform duration-300 hover:scale-110'
               onClick={() => scrollToSection('partners')}>
             Lending partners
            </p>
          </span>
          <div className='rounded-md p-2 px-4 bg-[#F5B170] cursor-pointer font-semibold transform transition-transform duration-300 hover:scale-105'>
          <ContactUsForm  handleError={handleError}/>
          </div>
        </span>
        <ThankyouModal isOpen={isResponseModalOpen} isError={isError} onClose={closeThankyouModal} />
      </div>
    </nav>
  );
};

export default Navbar;