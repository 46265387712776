import React from 'react';
import 'tailwindcss/tailwind.css';
import Poster from './poster';
import ProcessSection from './process-section';
import CompaniesSection from './companies-section';
import Testimonials from '../../components/testimonial';
import OurServicesSection from './our-services-section';
import {
  lendersDetails,
  happyCustomersDetails,
} from '../../constants/companies-section-constant';

const HomePage: React.FC = () => {
  return (
    <div>
      <Poster />
      <section id="our-services">
        <OurServicesSection />
      </section>
      <ProcessSection />
      <section id="partners">
        <CompaniesSection
          title={'Our lending partners'}
          companyDetails={lendersDetails}
          direction={'left'}
        />
      </section>
      <CompaniesSection
        title={'Happy clients'}
        companyDetails={happyCustomersDetails}
        direction={'right'}
      />
      <Testimonials />
    </div>
  );
};

export default HomePage;