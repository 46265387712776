import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
  } from '@chakra-ui/react'
  import React from 'react'
  import { IoIosCheckmarkCircleOutline } from "react-icons/io";
  import { RxCrossCircled } from "react-icons/rx";
  
  type ThankyouModalProps = {
    isOpen: boolean;
    isError: boolean;
    onClose: () => void;
  }
  
  const ThankyouModal: React.FC<ThankyouModalProps> = ({ isOpen, isError, onClose }) => {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered size={'md'}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent textAlign={'center'}>
          <ModalHeader>
            <span className='flex flex-col gap-7 justify-center items-center'>
            {isError ? <><RxCrossCircled color='red' size={70}/>
            <p>Submission Failed</p></>
            :<><IoIosCheckmarkCircleOutline color='green' size={70}/>
            <p>Submission Successful</p></>}
            </span>
        </ModalHeader>
          <ModalBody>
            {isError ? <p>We apologize for the inconvenience. An error occurred during your submission.
              Please retry or contact our support team.</p>
            :<p>We appreciate you submitting your information. You’ll hear from us shortly!</p>}
          </ModalBody>
  
          <ModalFooter textAlign={'center'} justifyContent={'center'}>
            <Button colorScheme={isError ? 'red' : 'green'} onClick={onClose}> OK </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  
  export default ThankyouModal;
  