export const lendersDetails = [
  { name: 'ICICI Bank', logo: 'icici.png', dimension:{w:200, h:60} },
  { name: 'Axis Bank', logo: 'axis.png', dimension:{w:200, h:60} },
  { name: 'Kotak Mahindra Bank', logo: 'kotak-mahindra.png', dimension:{w:200, h:60} },
  { name: 'Canara Bank', logo: 'canara-bank.png',dimension:{w:200, h:60} },
  { name: 'HDFC Bank', logo: 'hdfc.png', dimension:{w:80, h:80} },
  { name: 'City Union Bank', logo: 'city-union.jpg', dimension:{w:150, h:100} },
  { name: 'AU Bank', logo: 'au-bank.jpg', dimension:{w:80, h:80} },
  { name: 'SBI Bank', logo: 'sbi.png', dimension:{w:100, h:100} },
  { name: 'Electronica', logo: 'electronica.png', dimension:{w:130, h:100} },
  { name: 'BNP Paribas', logo: 'bnp-paribas.png', dimension:{w:150, h:60} },
  { name: 'RAR Finance', logo: 'rar.jpeg', dimension:{w:60, h:50} },
  ];

  export const happyCustomersDetails = [
    { name: 'BluSmart', logo: 'BluSmart.png', dimension:{w:160, h:35} },
    { name: 'Srijan Capital', logo: 'Srijan-Capital.png', dimension:{w:200, h:60} },
    { name: 'Greenwings', logo: 'Greenwings.png', dimension:{w:100, h:60} },
    { name: 'mPokket', logo: 'mPokket.svg', dimension:{w:200, h:60} },
    { name: 'Aviom', logo: 'Aviom.png', dimension:{w:200, h:60} },
    { name: 'Megha Holdings', logo: 'Megha Holdings.png', dimension:{w:200, h:60} },
    { name: 'TechAviom', logo: 'TechAviom.png', dimension:{w:200, h:60} },
    { name: 'Best Capital', logo: 'best-capital.jpg', dimension:{w:100, h:60} },
    { name: 'Mentor Homes', logo: 'mentor-homes.png', dimension:{w:150, h:60} },
  ];