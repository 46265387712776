import React from 'react';
import OurServicesCard from '../../components/card';
import { ourServicesData } from '../../constants/our-service-constant';

const OurServicesSection: React.FC = () => {
  return (
 <section className="py-12 pb-30">
  <div className="container mx-auto px-4 sm:px-6 lg:px-8">
    <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">Services we offer</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[100px] md:gap-15 mt-[5%]">
      {ourServicesData.map((card, index) => (
        <OurServicesCard
          key={index}
          Icon={card.Icon}
          title={card.title}
          description={card.description}
        />
      ))}
    </div>
  </div>
</section>
  );
};

export default OurServicesSection;
