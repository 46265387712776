import { useFormik } from 'formik';
import * as Yup from 'yup';
import { constant } from '../../constants/validation-constant';

type ContactUsFormProps = {
  onClose: () => void;
  handleError: (status: boolean) => void;
}

const useContactUsForm = ({ onClose, handleError }: ContactUsFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      cin: '',
      address: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(constant.NAME_VALIDATION_ERROR),

      email: Yup.string().email(constant.EMAIL_VALIDATION_ERROR).required(constant.EMAIL_VALIDATION_ERROR),

      phone: Yup.string().min(constant.PHONE_LENGTH, constant.PHONE_VALIDATION_ERROR)
        .max(constant.PHONE_LENGTH, constant.PHONE_VALIDATION_ERROR)
        .matches(/^[0-9]+$/i, constant.PHONE_VALIDATION_ERROR)
        .required(constant.PHONE_VALIDATION_ERROR),

      company: Yup.string().required(constant.COMPANY_NAME_VALIDATION_ERROR),

      cin: Yup.string().min(constant.CIN_LENGTH, constant.CIN_VALIDATION_ERROR)
        .max(constant.CIN_LENGTH, constant.CIN_VALIDATION_ERROR)
        .required(constant.CIN_VALIDATION_ERROR),

      address: Yup.string().optional(),
    }),

    onSubmit: async (values) => {
      try {
        const apiData = {
          ...values,
        };
        const response = await (await fetch('https://portal.ovikfinance.com/api/signup/contact-us', {
          body: JSON.stringify(apiData),
          headers: {'Content-Type': 'application/json'},
          method: 'POST',
        })).json();
        if (response.success === true) {
          formik.resetForm({
            values: {
              name: '',
              email: '',
              phone: '',
              company: '',
              cin: '',
              address: '',
            }
          });
          onClose()
          handleError(false)
        } else {
          onClose()
          handleError(true)
        }
      } catch (error) {
        onClose()
        handleError(true)
        console.log(error)
      }
    },
  });


  return {
    formik,
  };
};

export default useContactUsForm;

