import React from 'react';
import 'tailwindcss/tailwind.css';
import { ReactTyped } from "react-typed";

const Poster: React.FC = () => {
  return (
    <section
      className="bg-gradient-to-r from-[#FFFF] to-[#b3cce5] w-full min-h-[90vh] flex items-center pt-[100px]"
    >
      <div className="container mx-auto flex flex-col-reverse md:flex-row md:justify-between items-start md:items-center">
    
        <div className="w-[80%] ml-[10%] md:w-[50%] p-5 fade-in">
          <h1 className="text-3xl md:text-4xl font-bold mb-4 slide-in">
          <ReactTyped loop strings={["Lorem ipsum dolor", "consectetur adipiscing elit", "Praesent libero"]} typeSpeed={100} />
          </h1>
          <p className="text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
          </p>
        </div>

        <div className="w-[80%] lg:w-[40%] h-[400px] md:h-[650px] md:pr-2% ml-[10%] mb-6 md:mb-0 fade-in md:py-10 lg:py-0">
          <img
            className='w-full h-full rounded-xl'
            src="https://media.istockphoto.com/id/1311598658/photo/businessman-trading-online-stock-market-on-teblet-screen-digital-investment-concept.jpg?s=612x612&w=0&k=20&c=HYlIJ1VFfmHPwGkM3DtVIFNLS5ejfMMzEQ81ko534ak="
            alt=""/>
        </div>
      </div>
    </section>
  );
};

export default Poster;
