import React from 'react';
import 'tailwindcss/tailwind.css';
import { FaHandHoldingUsd, FaHandsHelping } from "react-icons/fa";
import { FaPeopleRoof } from "react-icons/fa6";
import { AiOutlineRise } from "react-icons/ai";

const ProcessSection: React.FC = () => {
  return (
    <div className='bg-gradient-to-l from-white to-[#fef7f1] py-40'>
      <div className='grid gap-10 place-content-center md:grid-cols-4 md:gap-20 md:place-content-stretch'>
        
        <div className='w-full text-center transform transition-transform duration-300 hover:scale-105'>
          <p className='flex items-center justify-center text-primary'> 
            <FaHandHoldingUsd size={'80'}/>
          </p>
          <p className='text-2xl md:text-3xl font-bold mt-5'>₹ 1000+ Cr.</p>
          <p className='lg:w-1/2 lg:ml-[25%] text-lg md:text-xl mt-4 border p-2 rounded-xl font-semibold'>Crore Debt Raised</p>
        </div>

        <div className='w-full text-center transform transition-transform duration-300 hover:scale-105'>
          <p className='flex items-center justify-center text-primary'> 
            <FaPeopleRoof size={'80'}/>
          </p>
          <p className='text-2xl md:text-3xl  font-bold mt-5'>90+</p>
          <p className='lg:w-1/2 lg:ml-[25%] text-lg md:text-xl mt-4 border p-2 rounded-xl font-semibold'>Clients on Board</p>
        </div>

        <div className='w-full text-center transform transition-transform duration-300 hover:scale-105'>
          <p className='flex items-center justify-center text-primary'> 
            <FaHandsHelping size={'80'}/>
          </p>
          <p className='text-2xl md:text-3xl font-bold mt-5'>150+</p>
          <p className='lg:w-1/2 lg:ml-[25%] text-lg md:text-xl mt-4 border p-2 rounded-xl font-semibold'>Deals</p>
        </div>

        <div className='w-full text-center transform transition-transform duration-300 hover:scale-105'>
          <p className='flex items-center justify-center text-primary'> 
            <AiOutlineRise size={'80'}/>
          </p>
          <p className='text-2xl md:text-3xl font-bold mt-5'>98%</p>
          <p className='lg:w-1/2 lg:ml-[25%] text-lg md:text-xl mt-4 border p-2 rounded-xl font-semibold'>Success Rates</p>
        </div>

      </div>
    </div>
  );
};

export default ProcessSection;
