import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormHelperText,
    useDisclosure,
    Button,
    FormLabel,
    Input,
    Textarea,
} from '@chakra-ui/react'
import React from 'react'
import useContactUsForm from './contact-us-form-hook'

type ContactUsFormProps = {
    handleError: (status: boolean) => void
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({ handleError }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { formik } = useContactUsForm({ onClose, handleError });

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    return (
        <>
            <div onClick={onOpen}>CONTACT US</div>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset='slideInBottom'
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Contact Us</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    ref={initialRef}
                                    placeholder='Name'
                                    value={formik.values.name}
                                    name='name'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                                {formik.touched.name &&
                                <FormHelperText color={'red'}>{formik.errors.name}</FormHelperText>}
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    placeholder='Email'
                                    value={formik.values.email}
                                    name='email'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                                {formik.touched.email &&
                                <FormHelperText color={'red'}>{formik.errors.email}</FormHelperText>}
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    type='number'
                                    placeholder='Phone Number'
                                    value={formik.values.phone}
                                    name='phone'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                                {formik.touched.phone &&
                                <FormHelperText color={'red'}>{formik.errors.phone}</FormHelperText>}
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Company</FormLabel>
                                <Input
                                    placeholder='Company Name'
                                    value={formik.values.company}
                                    name='company'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                                {formik.touched.company &&
                                <FormHelperText color={'red'}>{formik.errors.company}</FormHelperText>}
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>CIN</FormLabel>
                                <Input
                                    placeholder='Company Cin Number'
                                    value={formik.values.cin}
                                    name='cin'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                                {formik.touched.cin &&
                                <FormHelperText color={'red'}>{formik.errors.cin}</FormHelperText>}
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Address</FormLabel>
                                <Textarea
                                    placeholder='Headquarter Location'
                                    value={formik.values.address}
                                    name='address'
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.address &&
                                <FormHelperText color={'red'}>{formik.errors.address}</FormHelperText>}
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button type='submit' colorScheme='blue' mr={3}> Submit </Button>
                        </ModalFooter>
                    </form>

                </ModalContent>
            </Modal>
        </>
    )
}

export default ContactUsForm