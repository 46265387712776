import React from 'react';
import { IconType } from 'react-icons';

interface CardProps {
  Icon: IconType;
  title: string;
  description: string;
}

const OurServicesCard: React.FC<CardProps> = ({ Icon, title, description }) => {
  return (
   <div className="border border-neutral-200 bg-white dark:bg-boxdark rounded-lg shadow-md p-6 flex flex-col items-center text-center transform transition-transform duration-300 hover:scale-105">
      <Icon className="text-4xl text-primary mb-4 transition-colors duration-300" />
      <h3 className="text-lg md:text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  );
};

export default OurServicesCard;
