import { FaPiggyBank, FaAcquisitionsIncorporated } from 'react-icons/fa';
import { FcDebt, FcMoneyTransfer } from "react-icons/fc";
import { RiRefund2Line } from "react-icons/ri";
import { AiFillFund } from "react-icons/ai";


export const ourServicesData = [
    { Icon: FcDebt, title: 'Debt Syndication', description: '' },
    { Icon: RiRefund2Line, title: 'Equity Fundraise', description: '' },
    { Icon: FaPiggyBank, title: 'Investment Banking', description: '' },
    { Icon: FcMoneyTransfer, title: 'Bespoke Financing', description: '' },
    { Icon: AiFillFund, title: 'AIF Fundraise', description: '' },
    { Icon: FaAcquisitionsIncorporated, title: 'M&A', description: '' },
  ];