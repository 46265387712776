import React from 'react';
import 'tailwindcss/tailwind.css';
import Marquee from "react-fast-marquee";

type CompaniesSectionProps = {
  title: string,
  companyDetails: Array<{
    name: string,
    logo: string,
    dimension: { w: number, h: number }
  }>
  direction: 'left' | 'right';
}

const CompaniesSection: React.FC<CompaniesSectionProps> = ({ title, companyDetails, direction }) => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto text-center">
        <div className="px-5">
          <h2 className="text-2xl md:text-3xl font-bold mb-8">{title}</h2>
        </div>

        <div className="relative overflow-hidden mt-[20%] md:mt-[5%]">
          {/* Flex container for logos */}
          <Marquee speed={70} direction={direction} pauseOnHover={true}>
          <div
            className="flex space-x-12 hide-scrollbar"
            style={{ whiteSpace: 'nowrap' }}
          >
           {companyDetails.map((company, index) => (
              <div className="flex flex-col justify-end h-33" key={index}>
                <div
                  className="flex-shrink-0 mx-5"
                  style={{ height: `${company?.dimension?.h}px`, width: `${company?.dimension?.w}px` }}
                >
                  <img
                    src={`/${company.logo}`}
                    alt={company.name}
                    className="mx-auto"
                    style={{ height: `${company?.dimension?.h}px`, width: `${company?.dimension?.w}px` }}
                  />
                </div>
                <p className="text-lg text-black text-center font-semibold mt-5">{company.name}</p>
              </div>
            ))}
          </div>
           </Marquee>
        </div>
      </div>
    </section>
  );
};

export default CompaniesSection;
